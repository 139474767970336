<script setup>
import Checkbox from '@/Components/Checkbox.vue';
import GuestLayout from '@/Layouts/GuestLayout.vue';
import InputError from '@/Components/InputError.vue';
import InputLabel from '@/Components/InputLabel.vue';
import PrimaryButton from '@/Components/PrimaryButton.vue';
import TextInput from '@/Components/TextInput.vue';
import { Head, Link, useForm } from '@inertiajs/vue3';

defineProps({
    canResetPassword: {
        type: Boolean,
    },
    status: {
        type: String,
    },
});

const form = useForm({
    email: '',
    password: '',
    remember: false,
});

const submit = () => {
    form.post(route('login'), {
        onFinish: () => form.reset('password'),
    });
};
</script>

<template>
    <GuestLayout>
        <Head title="Log in" />

        <div class="max-w-md px-11 py-5 mx-auto w-full">
            <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
                {{ status }}
            </div>
            
            <h1 class="text-4xl mb-2">{{ __('Log in') }} <Link href="/" class="text-primary font-bold">Medskill</Link></h1>

            <p class="text-sm mb-12">{{ __('First time here?') }} <Link class="text-primary font-bold" :href="route('register')">{{ __('Register') }}</Link></p>

            <form @submit.prevent="submit">
                <div class="py-4 relative">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="absolute top-6 left-2 text-primary"
                    >
                        <circle cx="12" cy="12" r="4"></circle>
                        <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                    </svg>
                    <TextInput
                        id="email"
                        type="email"
                        class="w-full ps-10"
                        v-model="form.email"
                        required
                        autofocus
                        autocomplete="username"
                        placeholder="Email"
                    />

                    <InputError class="mt-2" :message="form.errors.email" />
                </div>

                <div class="py-4 relative">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="absolute top-6 left-2 text-primary"
                    >
                        <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                        <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                    </svg>
                    <TextInput
                        id="password"
                        type="password"
                        class="w-full ps-10"
                        v-model="form.password"
                        required
                        autocomplete="current-password"
                        :placeholder="__('Password')"
                    />

                    <InputError class="mt-2" :message="form.errors.password" />
                </div>

                <div class="flex items-center justify-between mt-4">
                    <label class="flex items-center">
                        <Checkbox name="remember" v-model:checked="form.remember" />
                        <span class="ms-2 text-sm text-gray-600">{{ __('Remember me') }}</span>
                    </label>

                    <PrimaryButton class="ms-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">{{ __('Log in') }}</PrimaryButton>
                </div>

            </form>

            <Link
                v-if="canResetPassword"
                :href="route('password.request')"
                class="block underline text-sm text-gray-600 hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-5 mb-20"
            >{{ __('Forgotten password') }}</Link>

            <p class="text-sm">
                © {{ new Date().getFullYear() }} {{ __('All rights reserved') }}. <Link :href="route('/')">Medskill</Link> {{ __('is a product owned by') }} Medskill Ltd.
            </p>
        </div>
    </GuestLayout>
</template>
